document.addEventListener("turbolinks:load", () => {
  const factorySelectBox = $('.models-form-factory-select')
  const robotSelectBox = $('.models-form-robot-select')

  if (factorySelectBox === null || robotSelectBox === null) {
    return
  }

  factorySelectBox.on('change', (e) => {
    updateRobotOptions(e.target.value)
  })

  const updateRobotOptions = (selectedFactoryId) => {
    $(robotSelectBox).find('option').each((_, option) => {
      const factoryId = $(option).data('factory-id')
      if (selectedFactoryId === factoryId) {
        $(option).show()
      } else {
        $(option).hide()
      }
    })
    robotSelectBox.prop('selectedIndex', null)
  }
})
