const resetRobots = (factorySelector) => {
  const editProductionLineRobots = document.getElementById('edit-production-line-robots');
  editProductionLineRobots.querySelectorAll('tr').forEach((tr) => {
    const factoryId = tr.dataset.factoryId;
    const selectedFactoryId = factorySelector.value;
    if (selectedFactoryId === factoryId) {
      tr.classList.remove('d-none');
      tr.classList.add('d-table-row');
    } else {
      tr.classList.remove('d-table-row');
      tr.classList.add('d-none');
      tr.querySelectorAll('input[type=checkbox]').forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  });
};

document.addEventListener('turbolinks:load', () => {
  const editProductionLineRobots = document.getElementById('edit-production-line-robots');
  if (!editProductionLineRobots) {
    return;
  }
  const factorySelector = document.getElementById('production_line_factory_id');
  factorySelector.addEventListener('change', (e) => { resetRobots(e.currentTarget) });
  resetRobots(factorySelector);
});
