const resetRobotPartTypes = (factorySelector) => {
  const editRobotRobotPartTypes = document.getElementById('edit-robot-robot-part-types');
  editRobotRobotPartTypes.querySelectorAll('tr').forEach((tr) => {
    const factoryId = tr.dataset.factoryId;
    const selectedFactoryId = factorySelector.value;
    if (selectedFactoryId === factoryId) {
      tr.classList.remove('d-none');
      tr.classList.add('d-table-row');
    } else {
      tr.classList.remove('d-table-row');
      tr.classList.add('d-none');
      tr.querySelectorAll('input[type=checkbox]').forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  });
};

document.addEventListener('turbolinks:load', () => {
  const editRobotRobotPartTypes = document.getElementById('edit-robot-robot-part-types');
  if (!editRobotRobotPartTypes ) {
    return;
  }
  const factorySelector = document.getElementById('robot_factory_id');
  factorySelector.addEventListener('change', (e) => { resetRobotPartTypes(e.currentTarget) });
  resetRobotPartTypes(factorySelector);
});
