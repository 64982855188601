$(function () {
  $('.js-total-price-trigger').on('change', (e) => {
    const amount = Number($(e.target).val());
    const unitPrice = Number($(e.target).closest('tr').find('.js-total-price-unit').data('unitPrice'));
    const totalPrice = amount * unitPrice
    if (typeof totalPrice === 'number') {
      $(e.target).closest('tr').find('.js-total-price-target').html("¥" + amount * unitPrice);
    }
  });
});
