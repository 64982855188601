document.addEventListener("turbolinks:load", () => {
  const productionLineSelectBox = $('.working-lines-form-production-line-select')
  const robotSelectBox = $('.working-lines-form-robot-select')

  if (productionLineSelectBox === null || robotSelectBox === null) {
    return
  }

  if ($(productionLineSelectBox).val()) {
    updateProductionLineOptions($(productionLineSelectBox).val())
  }

  productionLineSelectBox.on('change', (e) => {
    updateProductionLineOptions(e.target.value)
    robotSelectBox.prop('selectedIndex', 0)
  })

  const updateProductionLineOptions = (productionLineId) => {
    const isProductionLineIdEmpty = productionLineId === null || productionLineId === undefined || productionLineId === ""

    $(robotSelectBox).find('option').each((_, option) => {
      const productionLineIds = $(option).data('production-line-ids')
      const isIncluded = productionLineIds ? $(option).data('production-line-ids').split(',').includes(productionLineId) : false
      if (isIncluded || isProductionLineIdEmpty) {
        $(option).show()
      } else {
        $(option).hide()
      }
    })
  }
})
