document.addEventListener('turbolinks:load', () => {
  const updateStatusMapLink = (robotId) => {
    const statusMapLink = document.getElementById('status-map-link')
    if (statusMapLink instanceof HTMLAnchorElement) {
      const userGroupId = $('a.working-lines-status-map-link').data('user-group-id')
      const basicUser = encodeURIComponent($('a.working-lines-status-map-link').data('basic-user'))
      const basicPassword = encodeURIComponent($('a.working-lines-status-map-link').data('basic-password'))
      statusMapLink.href = `http://${basicUser}:${basicPassword}@ik1-424-44057.vs.sakura.ne.jp/${userGroupId}/${robotId}/servalcat/`
    }
  }

  const updateChartLink = (url) => {
    const chartLink = document.getElementById('chart-link')
    if (chartLink instanceof HTMLAnchorElement) {
      if (url != null) {
        chartLink.href = url
      } else {
        chartLink.href = 'javascript:void(0)'
      }
    }
  }

  const updateTableauView = (code) => {
    const tableauView = document.getElementById('tableau-view')
    if (code == null) {
      tableauView.innerHTML = ''
      return
    }
    tableauView.innerHTML = code
    // そのままだと script タグが実行されないため、scriptタグ部分だけ createElement で作ったものに置き換える
    tableauView.querySelectorAll('script').forEach(scriptElement => {
      const _script = document.createElement('script');
      _script.src = scriptElement.src
      _script.type = scriptElement.type
      scriptElement.replaceWith(_script)
    })
  }

  const updateLink = (url) => {
    const isRobotIdEmpty = url == null
    if (isRobotIdEmpty) {
      return
    }
    fetch(url).then(response => response.json()).then((robot) => {
      updateTableauView(robot.tableau_view_embed_code)
      updateStatusMapLink(robot.tokyo_tech_cgi_id)
      updateChartLink(robot.tableau_workbook_url)
    })
    return
  }

  const robotSelectBox = $('.working-lines-form-robot-select')

  if (robotSelectBox === null) {
    return
  }

  if ($(robotSelectBox).val()) {
    updateLink($(robotSelectBox).val())
  }

  robotSelectBox.on('change', (e) => {
    updateLink(e.target.value)
  })
})
