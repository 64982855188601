document.addEventListener('turbolinks:load', () => {
  const factorySelectBox = $('.robot_part_type_form_factory_select');
  const supplierSelectBox = $('.robot_part_type_form_supplier_select');

  if (factorySelectBox === null || supplierSelectBox === null) {
    return
  }

  if ($(factorySelectBox).val()) {
    updateSupplierOptions($(factorySelectBox).val());
  }

  factorySelectBox.on('change', (e) => {
    updateSupplierOptions(e.target.value)
  });

  function updateSupplierOptions(factoryId) {
    const isFactoryIdEmpty = factoryId === null || factoryId === undefined || factoryId === "";

    $(supplierSelectBox).find('option').each((i, option) => {
      if ($(option).data('factory-id') === factoryId || isFactoryIdEmpty) {
        $(option).show()
      } else {
        $(option).hide()
      }
    })
  }
});
