document.addEventListener("turbolinks:load", () => {
  const factorySelectBox = $('.working-lines-form-factory-select')
  const productionLineSelectBox = $('.working-lines-form-production-line-select')
  const robotSelectBox = $('.working-lines-form-robot-select')

  if (factorySelectBox === null || productionLineSelectBox === null || robotSelectBox === null) {
    return
  }

  const selectedFactoryId = $(factorySelectBox).val()
  if (selectedFactoryId) {
    updateProductionLineOptions(selectedFactoryId)
  }

  factorySelectBox.on('change', (e) => {
    updateProductionLineOptions(e.target.value)
    productionLineSelectBox.prop('selectedIndex', 0)
    robotSelectBox.prop('selectedIndex', 0)
  })

  const updateProductionLineOptions = (factoryId) => {
    const isFactoryIdEmpty = factoryId === null || factoryId === undefined || factoryId === ""

    $(productionLineSelectBox).find('option').each((_, option) => {
      if ($(option).data('factory-id') === factoryId || isFactoryIdEmpty) {
        $(option).show()
      } else {
        $(option).hide()
      }
    })
  }
})
